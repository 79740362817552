import React from 'react';
import styled from 'styled-components';
import { WorkshopsContext } from './Workshops';
import TrainingTile from './TrainingTile';
import { AppContext } from '../../App';

const WorkshopGrid = () => {

    const { trainings, instances, filters} = React.useContext(AppContext);

    const rendering = () => {

        // let filteredTrainings = trainings.filter((training) => training.name.toLowerCase().includes(searchQuery.toLowerCase()));
        let filteredTrainings = trainings.filter((training) => filters.includes(training.customProperties.category));

        return (
            <WorkshopGridSC>
                {filteredTrainings.map((training) => {

                    const filteredInstances = instances.filter((instance) => {
                        return instance.trainingId === training._id;
                    });

                    return (
                        <TrainingTile 
                            training={training}
                            filteredInstances={filteredInstances}    
                        />
                    );
                })}
            </WorkshopGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const WorkshopGridSC = styled.div`
    display : grid;
    grid-template-columns : repeat(auto-fill, minmax(240px, 1fr));
    grid-gap : 30px;
    transition : all 0.5s;

    @media (max-width: 480px) {
        max-width : 80vw;
        margin : 0 auto;
    }
`

export default WorkshopGrid