import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const ConfrimationPage = () => {

    const rendering = () => {
        return (
            <ConfrimationPageSC
                style={{backgroundImage : 'url(/hero-image.jpg)'}}
            >
                <div className='sign-up-confirmation-container'>
                    <img src='check-icon.png' alt='sign-up-confirmation' />
                    <h1>Successfully signed up!</h1>
                    <p>Please check your mail folder for confirmation. If you use <span style={{ fontWeight : 'bold'}}>outlook</span> make sure to check the <span style={{ fontWeight : 'bold'}}>spam folder</span></p>

                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => window.location = '/'}
                    >
                        Ok
                    </Button>
                </div>
            </ConfrimationPageSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ConfrimationPageSC = styled.div`
    min-width : 100vw;
    min-height : 100vh;
    display : flex;
    background-color : green;
    display: flex; 
    background-size : cover;
    background-position : center;
    justify-content : center;
    flex-direction : column;
    align-items : center;

    .sign-up-confirmation-container {
        background-color : white;
        padding : 40px;
        border-radius : 10px;
        box-shadow : 0 0 10px rgba(0,0,0,0.1);
        max-height : 60vh;
        min-width : 40vw;
        max-width : 40vw;
        display : flex;
        flex-direction : column;

        img {
            width : 150px;
            height : 150px;
            margin : 0 auto;
        }

        h1 {
            font-size : 2em;
            text-align : center;
            font-weight : 550;
            margin-bottom : 0;
        }

        p {
            font-size : 1.2em;
            text-align : center;
        }

        button {
            align-self : center;
            margin-top : 20px;
            min-width : 100px;
        }

        @media (max-width: 480px) {
            img { 
                width : 100px;
                height : 100px;
            }

            h1 {
                font-size : 1.5em;
            }

            p {
                font-size : 1em;
            }
        }

    }



`

export default ConfrimationPage