import React from 'react';
import styled from 'styled-components';
import Modal from '../generalComponents/Modal';
import { Button } from '@mui/material';

const SignUpConfirmed = ({ closeFunction }) => {

    const rendering = () => {
        return (
            <Modal>
                <SignUpConfirmedSC>
                <p style={{ padding : 0, margin : 0}}>You have succesfully signed up!</p>
                <p style={{ padding : 0, margin : 0, marginBottom : '20px'}}>Check your mailbox for more information</p>
                <Button
                        variant='contained'
                        color='primary'
                        onClick={() => closeFunction()}
                    >Close</Button>
                </SignUpConfirmedSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SignUpConfirmedSC = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    background-color : white;
    align-items : center;
    padding : 60px;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);

    >p {
        padding : 0;
    }

`

export default SignUpConfirmed