import React from 'react';
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';
import Axios from 'axios';
import { backEndAdress } from '../../variables';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


const ValidateDiscount = ({ 
    activeDiscountCode, 
    setActiveDiscountCode, 
    register, 
    errors, 
    discountCodeRef, 
    training, 
    discountNotEmty,
    validatingCode,
    setValidatingCode,
    codeValidated,
    setCodeValidated,
    codeNotValid,
    setCodeNotValid,
    emailValue,
    clearDiscount
}) => {

    const hasCode = discountNotEmty.length > 0 ? true : false;



    const getDiscountMessage = (discount) => {

        const { singleDiscountAmount , singleDiscountPercentage, discountType } = discount;

        if(discountType === 'regular') {
            if(singleDiscountAmount) {
                return `You will receive a discount of ${singleDiscountAmount}€`;
            }
            if(singleDiscountPercentage) {
                return `You will receive a discount of ${singleDiscountPercentage * 100}%`;
            }
        } 
    }

    const renderMultiDiscountInputFields = () => {
        
        const { combiDiscountOnNthAttendee } = activeDiscountCode;
        const fields = [];

        for(let i = 1; i < combiDiscountOnNthAttendee; i++) {
            fields.push(
                <TextField
                    id={`combi-email${i}`}
                    label={`Email ${i}`}
                    variant="outlined"
                    {...register(`combimail${i}`, {
                        required: 'Email is required',
                        validate: value =>
                            value !== emailValue || 'cannot be the same as your email'
                    })}                    
                    error={!!errors[`combimail${i}`]}
                    helperText={errors[`combimail${i}`]?.message}
                    autoComplete='off'
                    style={{ marginBottom : '15px'}}
                />
            )
        }

        return fields;
    }

    const validateCode = (e) => {
        let discountCode = discountCodeRef.current.querySelector('input').value;
        discountCode = discountCode.toUpperCase();

        const validateCode = async () => {

            setValidatingCode(true);

            try {
                const response = await Axios.get(`${backEndAdress}/api/discounts/by-code?discountCode=${discountCode}&trainingId=${training._id}`);
                setActiveDiscountCode(response.data);
            } catch (error) {
                setCodeNotValid(error.response.data.message); 
            }

        }

        validateCode();

    }

    const discountMessage = activeDiscountCode && getDiscountMessage(activeDiscountCode);

    const rendering = () => {
        return (
            <ValidateDiscountSC>
                {activeDiscountCode ? 
                
                    activeDiscountCode?.discountType === 'regular' ?

                    <div className='discount-succesful'>
                        <div className='discount-code-applied'>
                            <CheckIcon style={{ marginRight : '10px'}}/>
                            <p className='applied-discount-code'>{activeDiscountCode.discountCode}</p>
                            <ClearIcon className='clear-icon' onClick={() => clearDiscount()}/>
                        </div>
                        <p>{discountMessage}</p>
                    </div>
                    
                    : 
                    <>
                        <div className='discount-succesful'>
                            <div className='discount-code-applied'>
                                <CheckIcon style={{ marginRight : '10px'}}/>
                                <p className='applied-discount-code'>{activeDiscountCode.discountCode}</p>
                                <ClearIcon className='clear-icon' onClick={() => clearDiscount()}/>
                            </div>
                            <p>{discountMessage}</p>
                        </div>

                        <div className='multi-discount-code-input'>
                            <p>Fill in the e-mail adress of the other attendee. He/she will recieve a discount of {activeDiscountCode.combiDiscountPercentage ? 
                            `${activeDiscountCode.combiDiscountPercentage * 100}%`: `€${activeDiscountCode.combiDiscountAmount}`} automatically when signing up <br />
                            </p>
                            
                            {renderMultiDiscountInputFields()}

                            <p style={{ marginTop : '10px'}}>The other attendees also still need to sign up via this page! < br/>
                            they don't need to fill in any discount </p>
                        </div>
                    </>
                :
                    <div className='discount-code-input'>
                            <Button
                                type='button'
                                variant='contained'
                                onClick={(e) => validateCode(e)}
                                loading={true}
                                disabled={!hasCode}
                                style={{marginBottom : '10px'}}
                            >
                                Validate code
                            </Button>
                            {codeNotValid && 
                                <div className='error-message'>
                                    <p>error : {codeNotValid}</p>
                                </div>    
                            }
                    </div>
                }
            </ValidateDiscountSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ValidateDiscountSC = styled.div`
    .error-message {
        color: red;
        margin-top: 10px;
        border : 1px solid red;
        border-radius : 5px;
        padding-left : 20px;
    }

    .discount-succesful {
        display : flex; 
        flex-direction : column;
        justify-content : center;

        &>.discount-code-applied {
            display : flex;
            align-items : center;
            color : white; 
            color : #11b24b;
            border : 1px solid #11b24b;
            border-radius : 5px;    
            padding-left : 10px;
            padding-right : 10px;
            position : relative; 

            &>.clear-icon {
                cursor : pointer;
                position : absolute;
                right : 15px;
                top : 15px;
            }
        }
    
    }

    .multi-discount-code-input {
        border : 1px solid grey;
        padding : 20px;
        border-radius : 5px;
        display : flex; 
        flex-direction : column;
        margin-top : -15px;
        
        &>p {
            margin : 0;
            font-size : 14px;
            padding : 0;
            padding-bottom : 20px;

        }
    }

`

export default ValidateDiscount