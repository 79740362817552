import React from 'react';
import styled from 'styled-components';
import { TextField , Button, Stack } from '@mui/material';
import { set, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import InstancePicker from './InstancePicker';
import { IndividualTrainingContext } from './IndividualTraining';
import { backEndAdress } from '../../variables';
import SignUpConfirmed from './SignUpConfirmed';
import PreferedLanguageComponent from './PrefferedLanguageComponent';
import ValidateDiscount from './ValidateDiscount';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const ReactForm = ({ training }) => {

    document.addEventListener("keydown", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      });
      
    const discountCodeRef = React.useRef();

    const [confirm, setConfirm] = React.useState(false);
    const [activeDiscountCode, setActiveDiscountCode] = React.useState(null);
    const [discountNotEmty, setDiscountNotEmpty] = React.useState('');
    const [emailValue, setEmailValue] = React.useState('');


    // Validating code status states
    const [validatingCode, setValidatingCode] = React.useState(false);
    const [codeValidated, setCodeValidated] = React.useState(false);
    const [codeNotValid, setCodeNotValid] = React.useState(false);

    const { 
        selectedInstance : instance, 
        setSelectedInstance,
        setSignupConfirmedPopUp, 
        setSignupInProgress,
        setSignupError,
        setSignupCompleted,
        filteredInstances
    } = React.useContext(IndividualTrainingContext);

    const navigate = useNavigate();
    const form = useForm({
        defaultValues : {
            firstName : '',
            lastName : '',
            email : '',
            company : '',
            role : '',
        }
    });

    const clearDiscount = () => {
        setDiscountNotEmpty("")
        setActiveDiscountCode(null)
        setCodeNotValid(false)
    }

    const { register, handleSubmit, formState, setValue } = form;
    const { errors } = formState;

    const onSubmit = (data) => {

        let discountCode = discountCodeRef.current.querySelector('input').value;
        discountCode = discountCode.toUpperCase();
        const trainingPrice = training.pricePerDay * training.numberOfDays;
        
        const calculateDiscountValue = (discount, trainingPrice) => {
            const { singleDiscountAmount, singleDiscountPercentage, discountType, combiDiscountPercentage, combiDiscountAmount } = discount;

            if(discountType === 'regular') {
                if(singleDiscountAmount) {
                    return singleDiscountAmount;
                }
                if(singleDiscountPercentage) {
                    return trainingPrice * singleDiscountPercentage;
                }
            } else {
                if(combiDiscountAmount) {
                    return combiDiscountAmount;
                }
                if(combiDiscountPercentage) {
                    return trainingPrice * combiDiscountPercentage;
                }
            }
        }

        let combiDiscount = [];

        if(activeDiscountCode && activeDiscountCode.discountType === 'multi-user') {

            combiDiscount.push({
                discountCode : activeDiscountCode.discountCode,
                email : emailValue,
                discountValue : 0
            })

            const { combiDiscountOnNthAttendee } = activeDiscountCode;
            const discountAmount = calculateDiscountValue(activeDiscountCode, trainingPrice);

            for(let i = 1; i < combiDiscountOnNthAttendee; i++) {

                combiDiscount.push({
                    discountCode : activeDiscountCode.discountCode,
                    email : data[`combimail${i}`],
                    discountValue : i === combiDiscountOnNthAttendee - 1 ? discountAmount : 0
                })
            }
        }

        let singleDiscount = activeDiscountCode && activeDiscountCode.discountType === 'regular' ? calculateDiscountValue(activeDiscountCode, trainingPrice) : 0;
       
        setSignupInProgress(true);

        const addAttendee = async () => {
            try {
                const user = {
                    firstName : data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
                    lastName : data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1),
                    email : emailValue,
                    company : data.company,
                    role : data.role,
                    paymentStatus : 'pending',
                    receivedSurvey : false,
                    mailsReceived : [],
                    preferedLanguage : data.preferedLanguage,
                    emailAccounting : data.emailaccouting,
                    vatNumber : data.vatnumber,
                    discountCode : discountCode,
                    discountValue : singleDiscount,
                    signedUpTimestamp : moment().toISOString()
                }      

                const objToSend = {
                    attendee : user, 
                    instanceId : instance._id,
                    organisationId : training.organizationId,
                    combiDiscount : combiDiscount
                }

                const response = await Axios.post(`${backEndAdress}/api/instances/public/add-attendee`, objToSend);
                setSignupCompleted(response.data);
                // form.reset({
                //     firstName: '',
                //     lastName: '',
                //     email: '',
                //     company: '',
                //     role: '',
                //     preferedLanguage: '',
                //     emailaccouting: '',
                //     vatnumber: '',
                //     discountcode: ''
                // });
                // setSelectedInstance(null);
            } catch (error) {
                setSignupError(error.response.data.message);
            }
        }

        addAttendee();


    }

    const rendering = () => {
        return (
            <ReactFormSC>
                <>
                    {confirm && <SignUpConfirmed closeFunction={() => setConfirm(false)}/>}
                    <h1>Sign-up</h1>
                    <p className='personal-details' style={{ paddingBottom : '20px'}}>Select the training you want to join</p>

                    <InstancePicker training={training}/>                    
                    {filteredInstances.length > 0 && <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} width={'100%'}>
                        <p className='personal-details'>Personal details</p>
                        <>
                        <TextField
                            required
                            id="firstName"
                            label="First Name"
                            variant="outlined"
                            {...register('firstName',
                                { required : 'first name is required' }
                            )}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                            autoComplete='off'
                        />
                        <TextField
                            required
                            id="lastName"
                            label="Last Name"
                            variant="outlined"
                            {...register('lastName',
                                { 
                                    required : 'Last name is required'
                                }
                            )}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                            autoComplete='off'
                        />
                        <TextField
                            required
                            id="email"
                            label="Email"
                            variant="outlined"
                            {...register('email', 
                                { 
                                    required : 'Email is required', 
                                    pattern : {
                                        value : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message : 'Not a valid email'
                                    }
                                }
                            )}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            autoComplete='off'
                            onInput={(e) => setEmailValue(e.target.value)} 
                        />
                            <PreferedLanguageComponent
                                register={register}
                                errors={errors}
                                training={training}
                                setValue={setValue}
                                discountNotEmty={discountNotEmty}
                            />

                            {/* <TextField
                                required
                                id="firstName"
                                label="Preferred language"
                                variant="outlined"
                                {...register('preferedLanguage',
                                    { required : 'prefered language is required' }
                                )}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                                autoComplete='off'
                            /> */}
                            <TextField
                                required
                                id="company"
                                label="Company"
                                variant="outlined"
                                {...register('company',
                                    { required : 'Company is required' }
                                )}
                                error={!!errors.company}
                                helperText={errors.company?.message}
                                autoComplete='off'
                            />
                            <TextField
                                required
                                id="role"
                                label="Role"
                                variant="outlined"
                                {...register('role',
                                    { required : 'Role is required' }
                                )}
                                error={!!errors.role}
                                helperText={errors.role?.message}
                                autoComplete='off'
                            />
                            <p className='personal-details'>Payment details</p>

                            <TextField
                                id="discount-code"
                                label="Discount code"
                                variant="outlined"
                                {...register('discountCode')}
                                error={!!errors.discountCode}
                                helperText={errors.discountCode?.message}
                                autoComplete='off'
                                ref={discountCodeRef}
                                onChange={(e) => setDiscountNotEmpty(e.target.value)}
                                value={discountNotEmty}
                                style={{ visibility : activeDiscountCode ? 'hidden' : 'visible' , marginTop : activeDiscountCode ? '-50px' : '15px'}}
                                InputProps={{
                                    endAdornment: discountNotEmty && (
                                      <InputAdornment position="end">
                                        <IconButton onClick={() => {
                                            setDiscountNotEmpty("")
                                            setActiveDiscountCode(null)
                                            setCodeNotValid(false)
                                        }}
                                            edge="end">
                                          <ClearIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                            />
                        </>


                        <ValidateDiscount 
                            activeDiscountCode={activeDiscountCode}
                            register={register}
                            errors={errors}
                            setActiveDiscountCode={setActiveDiscountCode}
                            discountCodeRef={discountCodeRef}
                            training={training}
                            discountNotEmty={discountNotEmty}
                            validatingCode={validatingCode}
                            setValidatingCode={setValidatingCode}
                            codeValidated={codeValidated}
                            setCodeValidated={setCodeValidated}
                            codeNotValid={codeNotValid}
                            setCodeNotValid={setCodeNotValid}
                            emailValue={emailValue}
                            clearDiscount={clearDiscount}
                        />

                            <TextField
                                id="vat-number"
                                label="VAT Number"
                                variant="outlined"
                                {...register('vatnumber')}
                                error={!!errors.vatnumber}
                                helperText={errors.vatnumber?.message}
                                autoComplete='off'
                            />
                            <TextField
                                id="e-mail-accounting"
                                label="Email accounting"
                                variant="outlined"
                                {...register('emailaccouting')}
                                error={!!errors.emailaccouting}
                                helperText={errors.emailaccouting?.message}
                                autoComplete='off'
                            />
                            <Button 
                                disabled={!instance}
                                variant="contained" 
                                color="primary" 
                                type="submit"
                            >Submit</Button>
                            {!instance && <p className='not-able-to-submit'>please select session</p>}
                        </Stack>
                    </form>}
                </>
            </ReactFormSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ReactFormSC = styled.div`
    max-width : 100%;
    padding-left : 20px;
    padding : 30px;
    box-shadow : 0 0 5px rgba(0,0,0,0.1);
    box-sizing : border-box;
    border-radius : 10px;
    
    .personal-details {
        font-size : 18px;
        font-weight : bold;
        margin-bottom : 0; 
        line-height : 1;
        color: #D20A4B;
    }

    &>h1 {
        margin : 10px;
        padding-bottom : 10px;
        font-size : 26px;
        padding-left : 0;
        margin-left : 0;
    }

    form {
        max-width : 100%;

    }

    .not-able-to-submit {
        color : red;
        font-size : 14px;
        margin-top : 10px;
        align-self : flex-start;
    }


    .validate-code-button {
        color : grey;
        padding : 10px 20px;
        border : none;
        border-radius : 5px;
        cursor : pointer;
        margin-top : 10px;
        align-self : flex-start;
    }

    @media (max-width: 480px) {
        margin-top : 30px;
        margin-bottom : 30px;

    }

`

export default ReactForm