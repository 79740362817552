import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';
import { Button } from '@mui/material';

const SignUpConfirmedPopUp = () => {

    const { signupConfirmedPopUp, setSignupConfirmedPopUp } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <SignUpConfirmedPopUpSC>
                <div className='container-item'>
                    <p>You have succesfully signed up!</p>
                    <Button
                        variant='outlined'
                        onClick={() => setSignupConfirmedPopUp(false)}
                    >
                        close
                    </Button>
                </div>
            </SignUpConfirmedPopUpSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SignUpConfirmedPopUpSC = styled.div`
    position : fixed; 
    top : 0;
    left : 0;
    bottom : 0;
    right : 0;
    background-color : rgba(0, 0, 0, 0.5);
    display : flex;
    justify-content : center;
    align-items : center;
    z-index : 1000;

    .container-item {
        padding : 20px;
        background-color : white;
        border-radius : 5px;
        display : flex;
        flex-direction : column;
        align-items : center;
        justify-content : center;
        min-width : 500px;


    }
`

export default SignUpConfirmedPopUp