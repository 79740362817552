import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';

const HeroImage = () => {

    const { training } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <HeroImageSC>
                <div className='hero-image' style={{ backgroundImage : `url(/hero-image.jpg)`}}></div>
                    <div className='hero-text'>
                        <h1>{training.name}</h1>
                        <div className='training-properties'>
                            <div className='training-property'>
                                <CalendarMonthIcon fontSize={'large'} />
                                <p className='property-item-title'>Duration</p>
                                <div className='properties-list'>
                                    <p>{`${training.numberOfDays} day${training.numberOfDays > 1 ? 's' : ''}`}</p>
                                </div>
                            </div>
                            <div className='training-property'>
                                <LocationOnIcon fontSize={'large'} />
                                <p className='property-item-title'>Locations</p>
                                <div className='properties-list'>
                                    {training.customProperties.availableLocations.map((loc) => {
                                        return <p>{loc.place}</p>
                                    })}
                                </div>
                            </div>
                            <div className='training-property'>
                                <StarHalfIcon fontSize={'large'} />
                                <p className='property-item-title'>Difficulty</p>
                                <div className='properties-list'>
                                    <p>{training.customProperties.difficulty}</p>
                                </div>
                            </div>
                            <div className='training-property'>
                                <InterpreterModeIcon fontSize={'large'} />
                                <p className='property-item-title'>Languages</p>
                                <div className='properties-list'>
                                    {training.customProperties.availableLanguages.map((language) => {
                                        return <p>{language}</p>
                                    })}
                                </div>
                        </div>
                    </div>
                </div>
            </HeroImageSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeroImageSC = styled.div`
    min-height : 350px;
    min-width : 100vw;
    background-color : yellow;
    position : relative; 

    .hero-image {
        position : absolute; 
        top : 0;
        left : 0;
        bottom : 0; 
        right : 0;
        background-size : cover;
        background-position : center;
        background-repeat : no-repeat;
        display : flex;

        &::after {
            content : '';
            position : absolute;
            top : 0;
            left : 0;
            bottom : 0; 
            right : 0;
            z-index : 1;
            background-color: rgba(28, 64, 80, 0.8);

        }
    
    
    }

    .hero-text {
        position : absolute;
        top : 50%;
        left : 50%;
        transform : translate(-50%, -50%);
        z-index : 2;
        color : white;
        text-align : center;
        min-width : 60%;

        .training-properties {
            display : flex;
            justify-content : space-around;
        }

        h1 {
            font-size : 2.4em;
            font-weight : bold;
            margin : 0;
        }

        p {
            margin : 0;
        }
    }

    .training-properties {
        display : flex;
        justify-content : center;
        margin-top : 20px;

        .training-property {
            margin : 0 20px;
            display : flex;
            flex-direction : column;
            align-items : center;

            .property-item-title {
                font-weight : bold;
                margin-bottom : 5px;
            }

            p {
                margin : 0;
            }

            .properties-list {
                display : flex;
                flex-direction : column;
                align-items : center;
                font-size : 0.8em;
            }

            &>svg {
                margin-bottom : 10px;
                color : #d20a4b;
            }
        }


    }

    @media (max-width: 480px) {
        .hero-text {
            h1 {
                font-size : 1.4em;
            }
            
            .training-properties {
                gap : 15px;
            }
            .training-property {
                margin : 0;
                display : flex;
                flex-direction : column;
                align-items : center;

                .property-item-title {
                    font-size : 0.9em;
                    margin-bottom : 0px;
                }

                p {
                    margin : 0;
                }

                .properties-list {
                    display : flex;
                    flex-direction : column;
                    align-items : center;
                    font-size : 0.8em;
                }

                &>svg {
                    margin-bottom : 10px;
                    color : #d20a4b;
                }
            }
        }


    }
`

export default HeroImage