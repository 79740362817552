import React from 'react';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const PreferedLanguageComponent  = ({ training, setValue, register }) => {

    const handleChange = (event) => {
        setValue('preferedLanguage', event.target.value);
    }

    const rendering = () => {
        return (
            <PreferedLanguageComponentSC>
                    <FormControl
                        sx={{
                            width : '100%',
                        }}
                        fullWidth>
                        <InputLabel 
                            id="demo-simple-select-label"
                            sx={{
                                backgroundColor : 'white',
                                paddingRight : '5px',

                            }}    
                        >
                            Prefered language
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Age"
                            onChange={handleChange}
                            defaultValue='English'
                            {...register('preferedLanguage',
                                { required : 'prefered language is required' }
                            )}
                        >
                        {training.customProperties.availableLanguages.map((language, index) => {
                            return (
                                <MenuItem 
                                    value={language}
                                    key={index}

                                >{language}</MenuItem>
                            );
                        })}
                        </Select>
                    </FormControl>
            </PreferedLanguageComponentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PreferedLanguageComponentSC = styled.div`
    
`

export default PreferedLanguageComponent