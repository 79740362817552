import React from 'react';
import styled from 'styled-components';
import Modal from '../generalComponents/Modal';
import { CircularProgress } from '@mui/material';

const SendingMailModal = () => {

    const rendering = () => {
        return (
            <Modal>
                <SendingMailModalSC>
                    <p>sending e-mail</p>
                    <CircularProgress />
                </SendingMailModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SendingMailModalSC = styled.div`
    background-color : white;
    padding : 40px;
    border-radius : 5px;
    box-shadow : 0 0 5px rgba(0,0,0,0.1);
    display : flex;
    flex-direction : column;
    align-items : center;
    min-width : 300px;

    p {
        margin : 0; 
        padding : 0;
        padding-bottom : 20px;
    } 
`

export default SendingMailModal