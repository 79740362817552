import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@mui/material';
import Modal from './Modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ActionInProgress = ({ text, successText, errorText, completed, error, successOption1, successOption2, errorOption1, onSuccessOption}) => {
    return (
        <Modal>
            <WhiteContainer>
                {!completed && !error &&
                    <>
                        <div style={{ marginBottom : '10px'}}>{text}</div>
                        <StyledCircularProgress />
                    </>
                }

                {completed && onSuccessOption &&
                    onSuccessOption()
                }

                {completed && !onSuccessOption && 
                    <>
                        <Message style={{ marginBottom : '10px'}}>{successText}!</Message>
                        <CheckCircleIcon style={{ fontSize : '50px', color : 'green', marginBottom : '20px'}}/>
                        <div style={{ display : 'flex'}}>
                            <Button style={{ marginRight : '15px'}} variant = 'outlined' onClick={successOption1.onClick}>{successOption1.text}</Button>
                            {successOption2 && <Button variant = 'outlined' onClick={successOption2.onClick}>{successOption2.text}</Button>}
                        </div>
                    </>
                }
                {error && 
                <>
                    <Message>{`Someting went wrong : ${error}`}</Message>
                    <div style={{ display : 'flex'}}>
                        <Button  style={{ marginTop : '15px'}} variant = 'outlined' onClick={errorOption1.onClick}>{errorOption1.text}</Button>
                    </div>
                </>}
            </WhiteContainer>
        </Modal>
    );
};

const WhiteContainer = styled.div`
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    min-width: 300px;
    max-width: 450px;
    min-height : 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

const Message = styled.div`
    font-size: 18px;
    color: #333;
`;

const StyledCircularProgress = styled(CircularProgress)`
    margin-top: 20px;
`;

export default ActionInProgress;