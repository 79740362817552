import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../App';
import MenuIcon from '@mui/icons-material/Menu';
import ContactButton from './ContactButton';
const Header = () => {

    const { workshopsRef, scrollToSection, filters, setFilters } = React.useContext(AppContext);

    const [menuToggled, toggleMenu] = React.useState(false);

    const activateFilterAndScrollToWorkshops = (filterToActivate) => {
        setFilters(filterToActivate);
        scrollToSection(workshopsRef);
        toggleMenu(false);
    }

    const rendering = () => {
        return (
            <HeaderSC>
                <div className='header-logo'>
                    <img src='header-logo.png' alt='header-logo' />
                </div>
                <div className='header-menu'>
                    <div className='header-menu-item'>Home</div>
                    <div className='header-menu-item' onClick={() => activateFilterAndScrollToWorkshops('qlik')}>Qlik Workshops</div>
                    <div className='header-menu-item' onClick={() => activateFilterAndScrollToWorkshops('powerbi')}>Power BI Workshops</div>
                    <div className='header-menu-item'><ContactButton /></div>
                    
                    <button
                        onClick={() => window.open('https://credon.eu/', '_blank')}
                    >
                        Go to Credon.eu
                    </button>
                </div>
                <div className='header-menu-mobile'>
                    <MenuIcon 
                        onClick={() => toggleMenu(!menuToggled)}
                    />
                    {menuToggled &&
                        <div className='menu-options'>
                            <div className='header-menu-item'>Home</div>
                            <div className='header-menu-item' onClick={() => activateFilterAndScrollToWorkshops('qlik')}>Qlik Workshops</div>
                            <div className='header-menu-item' onClick={() => activateFilterAndScrollToWorkshops('powerbi')}>Power BI Workshops</div>
                            <div className='header-menu-item'><ContactButton /></div>
                            <button
                                onClick={() => window.open('https://credon.eu/', '_blank')}
                            >
                                Go to Credon.eu
                            </button>
                        </div>
                    }

                </div>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    display : flex; 
    min-height : 75px;
    background-color : white; 
    justify-content : space-between;
    align-items : center;
    z-index : 1000;

    .header-menu-mobile {
        visibility : hidden;
    }
    
    .header-logo {
        min-height : 75px;
        min-width : 75px;
        max-width : 280px;
        display : flex;
        align-items : center;
        padding-left : 30px;

        &>img {
            max-width : 100%;
            max-height : 100%;
        }
    }

    .header-menu {
        display : flex;
        padding-right : 25px;
        align-items : center;
        
        .header-menu-item {
            padding : 0px 20px;
            cursor : pointer;
            font-weight : 600;
            font-size : 15px;

            &:hover {
                color : #A3954E;
            }
        }
        
        button {
            background-color : #D20A4B;
            color : white;
            border : 0; 
            padding : 10px 20px;
            border-radius : 1px;
            font-weight : 600;
            font-size : 13px;
            font-family : inherit;
            max-width : 120px;
            margin-left : 20px;
            
            &:hover {
                cursor : pointer;
            }
        }
    
    }

    @media (max-width: 480px) {
    /* Styles for mobile phones */
        .header-menu {
            display : none;
        }

        .header-logo {
            padding-left : 20px;
            &>img {
                max-width : 60%;
                max-height : 70%;
            }   
        }

        .header-menu-mobile {
            visibility : visible;
            position : relative;

            >svg {
                cursor : pointer;
                margin-right : 20px;
                font-size : 30px;
            }
            .menu-options {
                display : flex;
                position : absolute; 
                flex-direction : column;
                top : 55px; 
                right : 0;
                z-index : 1000;
                background-color : white;

                .header-menu-item {
                    padding : 20px 30px;
                    cursor : pointer;
                    font-weight : 600;
                    font-size : 15px;
                    border : 1px solid lightgrey;
                    &:hover {
                        color : #A3954E;
                    }
                }

                button {
                    background-color : #D20A4B;
                    color : white;
                    border : 0; 
                    padding : 20px 30px;
                    border-radius : 1px;
                    font-weight : 600;
                    font-size : 13px;
                    font-family : inherit;
                    
                    &:hover {
                        cursor : pointer;
                    }
                }

            }
        }
    }
`

export default Header