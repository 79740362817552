import React from 'react';
import styled from 'styled-components';
import moment from 'moment'; 
import Selector from './Selector';
import TrainingInfo from './TrainingInfo';
import Dates from './Dates';

const TrainingTile = ({training, filteredInstances}) => {

    const navigateToTraining = (instanceId) => {
        window.location.hash = `/training/${training._id}/${instanceId ? instanceId : 'none'}`;
    }

    const [menuToShow, setMenuToShow] = React.useState('info');

    const rendering = () => {
        return (
            <TrainingTileSC >
                <div 
                    className='training-thumbnail-image' 
                    style={{ backgroundImage : `url(${training.imageUrl})`}}
                ></div>

                <div className='training-information'>
                    <p className='training-title'>{training.name}</p>

                    <Selector 
                        menuToShow={menuToShow}
                        setMenuToShow={setMenuToShow}
                    />
                    {menuToShow === 'info' && <TrainingInfo training={training}/>}
                    {menuToShow === 'dates' && <Dates filteredInstances={filteredInstances} navigateToTraining={navigateToTraining} training={training}/>}
                </div>

                <button onClick={() => navigateToTraining()}>More info</button>

            </TrainingTileSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingTileSC = styled.div`
    min-height : 600px;
    border-radius : 10px;
    overflow : hidden;
    display : flex;
    flex-direction : column;
    box-shadow : 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom : 15px;
    justify-content : space-between;

    .training-thumbnail-image {
        background-size : cover;
        background-position : center;
        background-repeat : no-repeat;
        height : 200px;
    }

    .training-information {
        display : flex;
        flex-direction : column;
        flex : 1;
        align-items : center;

        .training-title {
            min-height : 40px;
            display : flex;
            flex-direction : column;
            justify-content : center;
            font-size : 1.3em;
            font-weight : 700;
            text-align : center;
            padding-bottom : 0;
            max-width : 70%;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* limits text to 3 lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height : 76px;
        }

        .upcoming-dates {
            text-align : center;

            .line {
                min-width : 50px;
                min-height : 1px;
                background-color : lightgrey;
                margin : 0 auto;
            }
        }
    }

    .date-instance {
        display : flex;
        align-items : center;
        margin : 10px 0;

        p {
            margin : 0;
            padding-left : 10px;
        }
    }

    button {
        background-color : #1c4050;
        color : white;
        font-size : 1.2em;
        padding : 20px 20px;
        border : none;
        border-radius : 0px 0px 5px 5px;
        margin-top : 10px;
        cursor : pointer;

        &:hover {
            background-color : #A4964E;

        }
    }

    @media (max-width: 480px) {
        min-height : 480px;

        .training-thumbnail-image {
            background-size : cover;
            background-position : center;
            background-repeat : no-repeat;
            height : 150px;
        }
        
        .training-information {
            .training-title {
                font-size : 1.1em;
                max-width : 90%;
                margin : 15px;
                line-height : 1;
                min-height : 0;
            }
        }


    }
`

export default TrainingTile