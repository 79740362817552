import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../App';

const CTAHero = () => {

    const { scrollToSection, workshopsRef } = React.useContext(AppContext);

    const rendering = () => {
        return (
            <CTAHeroSC>
                <div className='hero-image' style={{ backgroundImage : `url(/hero-image.jpg)`}}></div>

                <div className='text-container'>
                    <p>For those who want to get their hands dirty and dig through their <span style={{fontWeight : 'bold'}}>data gold.</span></p>
                    <button
                        onClick={() => scrollToSection(workshopsRef)}
                    >GET STARTED</button>
                </div>
            </CTAHeroSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CTAHeroSC = styled.div`
    min-height : 400px;
    min-width : 100%;
    background-color : yellow;
    position : relative; 

    .hero-image {
        position : absolute; 
        top : 0;
        left : 0;
        bottom : 0; 
        right : 0;
        background-size : cover;
        background-position : center;
        background-repeat : no-repeat;
        display : flex;

        &::after {
            content : '';
            position : absolute;
            top : 0;
            left : 0;
            bottom : 0; 
            right : 0;
            z-index : 1;
            background-color: rgba(28, 64, 80, 0.9);

        }
    
    
    }

    .text-container {
        position : absolute;
        top : 50%;
        left : 50%;
        transform : translate(-50%, -50%);
        z-index : 2;
        color : white;
        text-align : center;
        min-width : 60%;

        p {	
            font-size : 2.3rem;
            font-weight : 500;
            text-align : center;
            max-width : 800px;
            margin-bottom : 20px;
        }

        &>button {
            background-color : #a3954e;
            border : 0; 
            padding : 20px 40px;
            color : white; 
            font-weight : bold;

            &:hover {
                cursor : pointer;
            }
        }
    }
    
    @media (max-width: 480px) {
        .text-container {
            p {
                font-size : 1.5rem;
            }
        }
    }


`

export default CTAHero