import React from 'react';
import styled from 'styled-components';

const Contact = () => {

    const rendering = () => {
        return (
            <ContactSC>
                <p className='phone-number'>+32 13 35 37 10</p>
                <p className='adress'>Industrieweg 116, 3980 Tessenderlo</p>
                <p className='email'>academy@credon.eu</p>
            </ContactSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ContactSC = styled.div`
    display : flex;
    flex-direction : column;
    min-width : 100%;
    align-items : center;
    padding : 100px 0;
    
    &>p {
        margin : 0;
        padding : 0;
    }

    .phone-number {
        font-size : 30px;
        font-weight : 500;
        color : rgb(28, 64, 80);    
        margin-bottom : 5px;
    }

    .adress {
        font-weight : 18px;
        font-weight : 300;
        color : rgb(24, 29, 46);
    }

    .email {
        font-weight : 18px;
        font-weight : 300;
        color : rgb(84, 89, 95)
    }

    @media (max-width: 480px) {
        padding : 50px 0 10px 0;
    }
`

export default Contact