import React from 'react';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';

const Selector = ({ menuToShow, setMenuToShow }) => {

    const changeSwitch = (e) => {
        e.target.checked ? setMenuToShow('dates') : setMenuToShow('info');
    }   

    const rendering = () => {
        return (
            <SelectorSC>
                <p>Details</p>
                <Switch onChange={(e) => changeSwitch(e)}/>
                <p>Dates</p>
            </SelectorSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SelectorSC = styled.div`
    display : flex; 
    align-items : center;
    max-width : 80%;
    font-weight : bold;
`

export default Selector