import React from 'react';
import styled from 'styled-components';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';

const Dates = ({ filteredInstances, navigateToTraining, training }) => {

    const orderedByDate = filteredInstances.sort((a, b) => {
        return new Date(a.startDate) - new Date(b.startDate);
    });




    const rendering = () => {
        return (
            <DatesSC>
                <p className='line'></p>
                <div>

                    { training.customProperties.onDemand ? 
                        <p>This training is on-demand</p>
                        :
                        filteredInstances.length === 0 ?
                            <div className='no-courses-div'>
                                <p>Currently no courses planned <br/>
                                reach out to <br />
                                 training@credon.eu</p>
                            </div>
                            :
                            orderedByDate.map((instance) => {
                                return (
                                    <div className='date-instance' onClick={() => navigateToTraining(instance._id)}>
                                        <CalendarMonthIcon />
                                        <p key={instance._id}>{moment(instance.startDate).format('DD/MM/YYYY')}</p>
                                    </div>
                                );
                            })}
                </div>
            </DatesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DatesSC = styled.div`
    &>div {
        .date-instance {
            &:hover {
                font-weight : bold;
                cursor : pointer;
            }
        }
    }

    .no-courses-div {
        box-sizing : border-box;
        line-height : 1;
        padding : 20px;
    }

    .no-courses-div {
        display : flex; 
        flex-direction : column;
        justify-content : center;
        align-items : center;
        text-align : center;
    }
`

export default Dates