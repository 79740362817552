import React from 'react';
import styled from 'styled-components';

const Modal = ({ children }) => {

    const rendering = () => {
        return (
            <ModalSC>
                {children}
            </ModalSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ModalSC = styled.div`
    min-width : 100vw;
    min-height : 100vh;
    top : 0;
    left : 0;
    bottom : 0;
    right : 0;
    position : fixed;
    display : flex;
    background-color : rgba(0, 0, 0, 0.2);
    justify-content : center;
    align-items : center;
    z-index : 1000000;
`

export default Modal