export const referencesArray = [{
    quote : 'Everything is explained well! I will definitely follow the Developer Workshop as well!',
    subInfo : 'Course followed: QlikView Designer',
    img : 'hospital_logistics_logo.jpg',
    company : 'Hospital Logistics',
    role : 'Project manager'
},{
    quote : 'Very interesting & educational! Practices we can now use every day! ', 
    subInfo : 'Course followed: Qlik Sense Designer',
    img : 'b&r.png',
    company : 'B&R Bouwgroep',
    role : 'Accountant'
},{
    quote : 'Straightforward & coherent',
    subInfo : 'Course followed: Qlik Developer',
    img : 'chillafish.jpg',
    company : 'Chillafish', 
    role : 'CEO'
},{
    quote : 'Nothing to add! Great workshop',
    subInfo: 'Course followed: Qlik Sense Designer',
    img : 'daelmans.jpg',
    company : 'Daelmans vastgoed',
    role : 'Finance Officer and Systems Administrator'
},{
    quote : 'Good starter to understand the basics of the front-end tool. Now its up to me!',
    subInfo: 'Course followed: Qlikview Designer',
    img : 'nusciensce.jpg',
    company : 'Nuscience Group',
    role : 'BI & Reporting specialist'
}]