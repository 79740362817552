import React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AppContext } from '../../App';
import { useNavigate } from 'react-router-dom';


const Footer = () => {

    const navigate = useNavigate();
    const { trainings } = React.useContext(AppContext);

    const onDemandWorkshops = trainings.filter(training => training.customProperties.onDemand === true);
    const permanentWorkshops = trainings.filter(training => training.customProperties.onDemand === false);

    const rendering = () => {
        return (
            <FooterSC>
                <div className="row first-row" style={{marginBottom : '50px', marginTop : '30px'}}>
                    <div className='col'>
                        <p className='subtitle'>LEARN FROM THE BEST IN THE FIELD</p>
                        <p className='footer-title'>World-class BI workshops</p>
                    </div>
                    <div className='col'>
                        <button
                            style={{
                                color : 'white',
                                backgroundColor : 'rgb(163, 149, 78)'
                            }}
                        >
                            OUR COURSES
                        </button>
                        <button
                            style={{
                                backgroundColor : 'white',
                                color : 'black'
                            }}
                        >
                            VISIT CREDON
                        </button>
                    </div>
                </div>
                <div className='row second-row' style={{justifyContent : 'flex-start' }}>
                    <div className='courses-list'>
                        <h1 className='courses-list-title'>Permanent workshops</h1>
                            <ul>
                                {permanentWorkshops.map((workshop, index) => {
                                    return (
                                        <li onClick={() => navigate(`/training/${workshop._id}`) }><ArrowRightIcon />{workshop.name}</li>
                                    )}
                                )}
                            </ul>
                    </div>
                    <div className='courses-list'>
                        <h1 className='courses-list-title'>Workshops on demand</h1>
                            <ul>
                                {onDemandWorkshops.map((workshop, index) => {
                                    return (
                                        <li onClick={() => navigate(`/training/${workshop._id}`) }><ArrowRightIcon />{workshop.name}</li>
                                    )}
                                )}
                            </ul>
                    </div>
                </div>
            </FooterSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FooterSC = styled.div`
    min-height : 520px;
    background-color : rgb(24, 29, 46);
    color : white;
    display : flex;
    flex-direction : column;
    min-width : 100%;
    align-items : center;

    p {
        margin : 0;
    }

    .row {
        max-width : 70%;
        min-width : 70%;
        display : flex;
        justify-content : space-between;
        padding-top : 50px;
    }

    .subtitle {
        font-weight : 500;
    }

    .footer-title {
        font-size : 2.5rem;
        font-weight : 500;
    }

    button {
        border : none;
        background-color : rgb(0, 0, 0);
        color : white;
        padding : 15px 30px;
        margin-top : 20px;
        cursor : pointer;
        margin-left : 40px;
        font-family : inherit;

    }

    .courses-list {
        margin-right : 100px;

        h1 {
            font-size : 1rem;
            font-weight : 500;
        }

        ul {
            list-style-type : none;
            padding : 0;
            margin : 0;
            color : lightgrey;
        }

        li {
            display : flex;
            align-items : center;
            margin-top : 3px;

            &:hover {
                color : white;
                cursor : pointer;
            }
        }
    }

    @media (max-width: 480px) {

        .first-row {
            display : flex; 
            flex-direction : column; 
        }

        .subtitle {
            font-size : 0.8rem;
        }

        .footer-title {
            font-size : 2rem;
        }

        .courses-list {
            margin : 0;

            h1 {
                font-size : 1.2rem;
            }

            li {
                font-size : 0.7rem;
            }
        }

        .second-row {
            margin : 0;
            padding-top : 0;
            padding-bottom : 50px;
        }
    }
`

export default Footer