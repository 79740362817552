import React from 'react';
import styled from 'styled-components';
import PriceAndContact from './PriceAndContact';

const TrainingInformation = ({ training }) => {

    const rendering = () => {
        if(training) {
            return (
                <TrainingInformationSC>
                    <div className='html-for-web' dangerouslySetInnerHTML={{ __html: training.htmlForWebsite }}>

                    </div>
                    <PriceAndContact training={training}/>

                </TrainingInformationSC>
            );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingInformationSC = styled.div`
    display : flex;
    flex-direction : column;
    padding-right : 20px;
    box-sizing : border-box;
    font-family : 'Montserrat', sans-serif;

    .html-for-web {
        max-width : 100%;


        h1 {
            color : #D20A4B;
            font-weight : 400;
            font-size : 1.4rem;
        }

        >ul {
            max-width : 100%;
            
            li {
                max-width : 100%;
                word-wrap : break-word;
                padding : 0;

                p {
                    max-width : 100%;
                }

            }
        }

        p {
            margin : 0;
            color : #54595f;
            font-family : 'Montserrat', sans-serif;
            font-weight : 400;

        }
    }
`

export default TrainingInformation