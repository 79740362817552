import React from 'react';
import styled from 'styled-components';
import { set, useForm } from 'react-hook-form';
import { TextField, Button, Stack } from '@mui/material';
import Axios from 'axios'; 
import SendingMailModal from './SendingMailModal';
import { backEndAdress } from '../../variables';
import SendingMailSuccesModal from './SendingMailSuccesModal.js';

const OnDemandTraining = ({ training }) => {

    const form = useForm({
        defaultValues : {
            firstName : '',
            lastName : '',
            email : '',
            company : '',
            role : '',
        }
    });

    const { register, handleSubmit, formState } = form;
    const { errors } = formState;

    const [sendingMailLoading, setSendingMailLoading] = React.useState(false);
    const [sendingMailSuccess, setSendingMailSuccess] = React.useState(false);

    const onSubmit = (data) => {

        setSendingMailLoading(true);

        const requestInformation = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/trainings/request-info-on-demand/credon/${training._id}`, {
                    firstName : data.firstName,
                    lastName : data.lastName,
                    email : data.email,
                    company : data.company,
                    message : data.message
                });
                setSendingMailLoading(false);
                setSendingMailSuccess(true);
            } catch (error) {
                console.log(error);
            }
        }

        requestInformation();
    };

    const rendering = () => {
        return (
            <OnDemandTrainingSC>
                {sendingMailLoading && <SendingMailModal />}
                {sendingMailSuccess && <SendingMailSuccesModal closeFunction={() => setSendingMailSuccess(false)} />}
                <h1>On-demand training</h1>
                <p>There are no scheduled courses for this training. You can request this workshop for your company.</p>
                <p>We offer workshops at our locations, but are also happy to give a workshop on a location decided by you.  </p>

                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} width={'100%'}>
                            <p className='request-info'>Request more information</p>
                        <TextField
                            required
                            id="firstName"
                            label="First Name"
                            variant="outlined"
                            {...register('firstName',
                                { required : 'first name is required' }
                            )}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                            autoComplete='off'
                        />
                        <TextField
                            required
                            id="lastName"
                            label="Last Name"
                            variant="outlined"
                            {...register('lastName',
                                { 
                                    required : 'Last name is required'
                                }
                            )}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                            autoComplete='off'
                        />
                        <TextField
                            required
                            id="email"
                            label="Email"
                            variant="outlined"
                            {...register('email', 
                                { 
                                    required : 'Email is required', 
                                    pattern : {
                                        value : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message : 'Not a valid email'
                                    }
                                }
                            )}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            autoComplete='off'
                        />

                            <TextField
                                required
                                id="company"
                                label="Company"
                                variant="outlined"
                                {...register('company',
                                    { required : 'Company is required' }
                                )}
                                error={!!errors.company}
                                helperText={errors.company?.message}
                                autoComplete='off'
                            />

                            <TextField
                                required
                                id="message"
                                label="Message"
                                variant="outlined"
                                {...register('message',
                                    { required : 'This field is required' }
                                )}
                                error={!!errors.message}
                                helperText={errors.message?.message}
                                autoComplete='off'
                                multiline
                                fullWidth
                                sx={{
                                    width: '100%',
                                    height: '300px', // Ensure the text field takes the full height of the parent div
                                    '& .MuiInputBase-root': {
                                    height: '100%', // Ensures input area fills the div,
                                    alignItems: 'flex-start',
                                    },
                                }}
                            />

                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"
                            >Submit</Button>
                        </Stack>
                </form>
            </OnDemandTrainingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const OnDemandTrainingSC = styled.div`
    max-width : 100%;
    padding-left : 20px;
    padding : 30px;
    box-shadow : 0 0 5px rgba(0,0,0,0.1);
    box-sizing : border-box;
    border-radius : 10px;
    
    .request-info {
        font-size : 18px;
        font-weight : bold;
        margin-bottom : 0; 
        line-height : 1;
        color: #D20A4B;
    }
`

export default OnDemandTraining