import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
const Socials = () => {

    const rendering = () => {
        return (
            <SocialsSC>
                <FacebookIcon
                    onClick={() => window.open('https://www.facebook.com/CredonEU/', '_blank')}
                />
                <LinkedInIcon
                    onClick={() => window.open('https://www.linkedin.com/company/credon', '_blank')}
                />
                <YouTubeIcon 
                    onClick={() => window.open('https://www.youtube.com/channel/UCW9A9NiSTxYZ7W0HQDLHrRw', '_blank')}
                />
                <InstagramIcon 
                    onClick={() => window.open('https://www.instagram.com/credon_eu/', '_blank')}
                />
            </SocialsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SocialsSC = styled.div`
    display : flex; 
    justify-content : center;
    align-items : center;
    min-width : 100%;
    gap : 50px;
    margin-bottom : 80px;
    &>svg {
        font-size : 30px;
        margin : 0 10px;
        color : rgb(28, 64, 80);
        
        &:hover {
            cursor: pointer; 
            color : #D20A4B;
        }

    }

    @media (max-width: 480px) {
        margin-bottom : 20px;
        margin-top : 20px;
        gap : 10px;
        padding-bottom : 20px;
    }
`

export default Socials