import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../App';

const Hero = () => {

    const { workshopsRef, scrollToSection } = React.useContext(AppContext);

    const rendering = () => {
        return (
            <HeroSC>
                <div className='hero-image' style={{backgroundImage : 'url(/hero-image.jpg)'}}> 
                    <div className='hero-text'>
                        <h1>ARE YOU UP TO DATA?</h1>
                        <p>Let’s get your business up to data during one of our Business Intelligence workshops! </p>
                        <button
                            onClick={() => scrollToSection(workshopsRef)}
                        >GET STARTED</button>

                    </div>
                    <img src='/logo-academy.png' alt='logo-academy' />
                </div>
            </HeroSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeroSC = styled.div`
    min-width : 100%;
    max-width : 100%;
    min-height : 600px;

    .hero-image {
        min-width : 100%;
        max-width : 100%;
        min-height : 600px;
        background-size : cover;
        background-position : center;
        position : absolute;

        &>img {
            max-width : 200px;
            max-height : 200px;
            position : absolute;
            top : 50%;
            right : 5%;
            transform : translate(-50%, -50%);
        }
    }

    .hero-text {
        background-color : #EAEAEA; 
        border : 5px solid white; 
        max-width : 550px;
        padding : 35px;
        position : absolute;
        top : 20%;
        left : 10%;
        display : flex;
        flex-direction : column;

        h1 {
            font-size : 2.5em;
            line-height : 0;
        }

        p {
            font-size : 1.2em;
            font-weight : 500;
        }

        button {
            background-color : #1C4050;
            padding : 15px;
            color : white;
            border : 0;
            font-size : 1.em;
            font-family : inherit;
            max-width: 200px;
            font-weight : 600;
        }
    }

    @media (max-width: 480px) {
    /* Styles for mobile phones */
        .hero-image {
            min-width : 100vw;
            background-size : cover;
            background-position : center;
            position : absolute;

            &>img {
                visibility : hidden;
            }

            .hero-text {    
                max-width : 250px;
                padding : 25px;
                position : absolute;
                top : 15%;
                left : 5%;
                background-color : rgba(255, 255, 255, 0.9);

                h1 {
                    font-size : 1.5em;
                    line-height : 1;
                    margin : 0;
                }

                p {
                    font-size : 1em;
                    font-weight : 500;
                    margin :0;
                }

                button {
                    margin-top : 15px;
                    background-color : #1C4050;
                    padding : 15px;
                    color : white;
                    border : 0;
                    font-size : 1.em;
                    font-family : inherit;
                    max-width: 200px;
                    font-weight : 600;
                }
            }
        }
    }

`

export default Hero