import { Radio } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IndividualTrainingContext } from './IndividualTraining';
import moment from 'moment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const InstancePicker = ({ training }) => {

    const { filteredInstances, selectedInstance, setSelectedInstance } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        if(!filteredInstances) return null;
        return (
            <InstancePickerSC>
                {training.customProperties.onDemand ? 
                        <p>This training is on-demand</p>
                        :
                        filteredInstances.length === 0 ?
                            <div className='no-courses-div'>
                                <p>Currently no courses planned <br/>
                                reach out to <br />
                                 training@credon.eu</p>
                            </div>
                            :
                            filteredInstances.map((instance) => {

                                const isSelected = selectedInstance ? selectedInstance._id === instance._id : false;

                                return (
                                    <div 
                                        className={`start-date-item ${isSelected ? 'selected' : ''}`} 
                                        onClick={() => setSelectedInstance(instance)}    
                                    >
                                        {isSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                        <div className='properties-small'>
                                            <p>{moment(instance.startDate).format('DD/MM/YYYY')}</p>
                                            <p style={{marginLeft : '10px'}}>{instance.location.place}</p>
                                        </div>
                                    </div>
                                );
                            })
                }
                        
            </InstancePickerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstancePickerSC = styled.div`
    margin-bottom : 20px;
    display : flex;
    flex-direction : column;
    max-width : 100%;
    
        .start-date-item {
            display : flex;
            align-items : center;
            padding : 5px;
            padding-left:  20px;
            border : 1px solid #e5e5e5;
            border-radius : 5px;
            cursor : pointer;
            margin-bottom : 10px;

            &:hover {
                background-color : #e5e5e5;
            }

            .properties-small {
                display : flex; 
                padding-left : 10px;
            }

            @media (max-width: 380px) {
                font-size : 13px;
            }
            

        }

        .selected {
            background-color : rgba(28,64,80,0.05)
        }

        @media (max-width: 360px) {

        }
`

export default InstancePicker