import React from 'react';
import styled from 'styled-components';
import Search from './Search';
import WorkshopGrid from './WorkshopGrid';
import Filtering from './Filtering';
import { AppContext } from '../../App';

export const WorkshopsContext = React.createContext();

const Workshops = () => {

    const { workshopsRef, trainings, instances, setTrainings, setInstances, filters, setFilters } = React.useContext(AppContext);
    
    const rendering = () => {
        return (
            <WorkshopsContext.Provider 
                value={{
                    instances, 
                    setInstances, 
                    trainings, 
                    setTrainings,
                    filters,
                    setFilters
                }}
            >
                <WorkshopsSC ref={workshopsRef} >
                    <Filtering />
                    <WorkshopGrid />
                </WorkshopsSC>
            </WorkshopsContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const WorkshopsSC = styled.div`
    display : flex;
    flex-direction : column;
    max-width : 1200px;
    margin : 0 auto;
    padding: 0 20px;

`

export default Workshops