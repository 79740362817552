import React from 'react';
import styled from 'styled-components';

const GoogleMaps = () => {



    const rendering = () => {
        return (
            <GoogleMapsSC>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2386.7245571535577!2d5.1550638762585415!3d51.06176354316107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c1375760c3356d%3A0x295df63413766a29!2sCredon!5e1!3m2!1sen!2sbe!4v1729160496990!5m2!1sen!2sbe" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                    >
                </iframe>
            </GoogleMapsSC>
        );

    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const GoogleMapsSC = styled.div`
    width : 100%;
    min-height : 400px;

    &>iframe {
        width : 100%;
        height :400px;
        border : none;
    }
`

export default GoogleMaps