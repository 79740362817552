import React from 'react';
import styled from 'styled-components';

const ReferenceTile = ({ reference }) => {

    console.log('reference', reference);

    const rendering = () => {
        return (
            <ReferenceTileSC>
                <div className='chat-box'>
                    <p className='quote'>{reference.quote}</p>
                    <p className='footer-info'>
                        {reference.subInfo}
                    </p>
                </div>
                <div className='company-info'>
                    <img src={`/${reference.img}`} alt='credon-logo' />
                    <div className='company-information'>
                        <h1>{reference.company}</h1>
                        <p>{reference.role}</p>
                    </div>
                </div>
            </ReferenceTileSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ReferenceTileSC = styled.div`
    display : flex;
    flex-direction : column;
    user-select: none;
    min-height : 300px;
    .chat-box {
        margin : 10px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        max-width : 80%;
        padding : 40px;
        box-sizing : border-box;
        border-radius : 10px;

        .quote {
            font-weight : bold; 
            margin : 0;
        }

        .footer-info {
            font-size : 0.8rem;
            color : grey;
            margin : 0;
        }
    }

    .company-info {
        display : flex; 
        margin-top : 20px;

        &>img {
            max-height : 60px;
            max-width : 60px;
            border-radius : 100%;
            margin-right : 15px;
        }

        .company-information {
            display : flex;
            flex-direction : column;
            justify-content : center;

            h1 {
                margin : 0;
                font-size : 1.2rem;
                color : rgb(164, 150, 78);
            }

            p {
                margin : 0;
                font-size : 0.8rem;
                color : grey;
            }
        }
    }

    @media (max-width: 480px) {
        max-width : 90vw;
        margin : 0 auto;

        .chat-box {
            min-width : 90%;
        }

    }
`

export default ReferenceTile