import React from 'react';
import styled from 'styled-components';
import { WorkshopsContext } from './Workshops';

const Search = () => {

    const { searchQuery, setSearchQuery } = React.useContext(WorkshopsContext);



    const rendering = () => {
        return (
            <SearchSC>
                <input 
                    type='text' 
                    placeholder='Search workshops' 
                    onChange={(e) => setSearchQuery(e.target.value)}    
                />
            </SearchSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SearchSC = styled.div`
    padding : 10px;
    box-sizing : border-box;
    min-width : 75vw;
    max-width : 75vw;
    margin : 0 auto;
    margin-bottom : 15px;

    input {
        width : 100%;
        padding : 20px;
        font-size : 1.2em;
        font-family : inherit;
        box-sizing : border-box;
        border : 1px solid lightgrey;
        border-radius : 5px;
        outline : none;
    }
`

export default Search