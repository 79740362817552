import React from 'react';
import styled from 'styled-components';
import Modal from '../generalComponents/Modal';
import { Button } from '@mui/material';

const SendingMailSuccesModal = ({ closeFunction }) => {

    const rendering = () => {
        return (
            <Modal>
                <SendingMailSuccesModalSC>
                    <h1>Thank you for your request!</h1>
                    <p>We will contact you as soon as possible.</p>
                    <Button 
                        variant="outlined" 
                        color="primary"
                        onClick={() => closeFunction()}    
                    >Close</Button>
                </SendingMailSuccesModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SendingMailSuccesModalSC = styled.div`
    background-color : white;
    padding : 35px;
    border-radius : 10px;
    box-shadow : 0 0 5px rgba(0,0,0,0.1);
    text-align : center;
    box-sizing : border-box;

    h1 {
        color : #D20A4B;
        font-weight : 500;
        font-size : 1.4rem;
        margin : 0;
        padding-bottom : 0;
    }

    p {
        margin : 0;
        padding : 0;
    }

    button {
        margin-top : 20px;
    }
`

export default SendingMailSuccesModal