import React from 'react';
import styled from 'styled-components';

const SubHeroText = () => {

    const rendering = () => {
        return (
            <SubHeroTextSC>
                <h1>Get up to data
                with your data!</h1>
                <p>During our BI workshops we’ll show you all the do’s and don’ts so you’re all set for some data exploring! You’ll learn from the best. That has some advantages you know. They’ll tell you all their (dirty) BI secrets, their best practices, their shortcuts. </p>
            </SubHeroTextSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SubHeroTextSC = styled.div`
    text-align : center;
    margin-top : 50px;
    margin-bottom : 50px;
    display : flex; 
    flex-direction : column;
    max-width : 1200px;
    margin : 55px auto;
    margin-bottom : 80px;
    padding: 0 20px;
    h1 {
        max-width : 450px;
        font-size : 3rem;
        color : #A4964E;
        margin-bottom : 20px;
        text-align : center;
        margin : 0 auto;
        margin-bottom : 20px;
    }

    p {
        font-size : 1.2rem;
        color : #1c4050;
        margin : 0 auto;
        font-weight : 500;
    }

    @media (max-width: 480px) {
    /* Styles for mobile phones */
        margin : 0;
        margin-top : 40px;

        h1 {
            font-size : 2rem;
            max-width : 300px;
            margin-top : 20px;
        }

        p {
            font-size : 1rem;
            max-width : 300px;
        }
    }
`

export default SubHeroText