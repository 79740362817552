import React from 'react';
import styled from 'styled-components';
import HeroImage from './hero-image';
import Axios from 'axios'; 
import TrainingInformation from './training-information';
import SignUpForm from './SignUpForm';
import SignUpConfirmedPopUp from './SignUpConfirmedPopUp';
import PriceAndContact from './PriceAndContact';
import OnDemandTraining from './OnDemandTraining';
import { backEndAdress } from '../../variables';
import ActionInProgress from '../generalComponents/ActionInProgress';
import { useNavigate } from 'react-router-dom';

export const IndividualTrainingContext = React.createContext();

const IndividualTraining = () => {

    const trainingIdFromURL = window.location.hash.split('/')[2];
    const instanceIdFromURL = window.location.hash.split('/')[3];

    const navigate = useNavigate();

    const [training, setTraining] = React.useState(null);
    const [instances, setInstances] = React.useState([]);
    const [filteredInstances, setFilteredInstances] = React.useState([]);
    const [selectedInstance, setSelectedInstance] = React.useState(null);
    const [signupConfirmedPopUp, setSignupConfirmedPopUp] = React.useState(false);

    const [signupInProgress, setSignupInProgress] = React.useState(false);
    const [signupError, setSignupError] = React.useState(null);
    const [signupCompleted, setSignupCompleted] = React.useState(null);

    React.useEffect(() => {
        const getTraining = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/public/${trainingIdFromURL}`);
                
                console.log('ind training', response.data);
                setTraining(response.data[0]);
            } catch (error) {
            }
        }

        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/public/get-instances-for-website`);
                const instancesNotCustomerLocation = response.data.filter((instance) => instance.locationType === 'our-location' && !instance.cancelled);
                setInstances(instancesNotCustomerLocation);
                return instancesNotCustomerLocation;
            } catch (error) {
                console.log(error);
            }
        }

        const runFunction = async () => {
            await getTraining();
            const instances = await getInstances();

            if(instanceIdFromURL) {
                setSelectedInstance(instances.filter((instance) => instance._id === instanceIdFromURL)[0]);
            }

        }

        runFunction();
        
    }, []);

    React.useEffect(() => {
        if(!training || !instances) return;
        const filteredInstances = instances.filter(instance => instance.trainingId === training?._id);
        const sortedInstances = filteredInstances.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        setFilteredInstances(sortedInstances);
    } , [instances, training]);

    const rendering = () => {

        if(training && instances) {       
            return (
                <IndividualTrainingContext.Provider 
                    value={{
                        training, 
                        filteredInstances,
                        setSelectedInstance,
                        selectedInstance,
                        signupConfirmedPopUp,
                        setSignupConfirmedPopUp,
                        setSignupInProgress,
                        setSignupError,
                        setSignupCompleted,
                        setSelectedInstance
                    }}
                >
                    <IndividualTrainingSC>
                        {signupInProgress && 
                            <ActionInProgress
                                text={'Signing up...'}
                                successText='Singup successful. Please check your mail folder for confirmation. If you use outlook make sure to check the spam folder'
                                completed={signupCompleted}
                                error={signupError}
                                successOption1={{onClick : () => setSignupInProgress(false), text : 'Ok'}}
                                errorOption1={{onClick : () => setSignupInProgress(false), text : 'Ok'}}
                                onSuccessOption={() => navigate(`/sign-up-confirmation/${selectedInstance}`)}
                            />
                        }


                        
                        {/* {signupConfirmedPopUp && <SignUpConfirmedPopUp />} */}
                        <HeroImage training={training}/>
                        <div className='breadcrumbs'>
                            <p><a href='/'>Trainings</a> /   <span style={{textDecoration : 'underline', paddingRight:  '100px'}}>{`   ${training.name}`}</span></p>
                        </div>
                        <div className='training-information-container'>
                            <TrainingInformation 
                                training={training}
                            />
                            {!training.customProperties.onDemand ?
                                <SignUpForm training={training}/>
                            : 
                                <OnDemandTraining training={training}/>
                            }                            
                        </div>
                    </IndividualTrainingSC>
                </IndividualTrainingContext.Provider>
            );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainingSC = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
    max-width : 100vw;
    box-sizing : border-box;
    overflow : hidden;

    a {
        text-decoration : none;
        color : inherit;
    }

    .breadcrumbs {
        display : flex; 
        justify-content : flex-start;
        min-width : 100%;
        padding : 15px;
        padding-left : 0;
        box-sizing : border-box;
        color : grey;
        max-width : 70vw;
        min-width : 70vw;
    }

    .training-information-container {
        display : flex;
        max-width : 70vw;
        min-width : 70vw;
        margin-top : 25px;



        >div {
            min-width : 50%;
            max-width : 50%;
        }
    }

    @media (max-width: 480px) {
        .training-information-container {
            padding : 0;
            display : flex;
            flex-direction : column;
            align-items : center;
            max-width : 90vw;
            min-width : 90vw;
            margin-top : 0;
            margin-bottom : 0;

            >div {
                min-width : 95%;
                max-width : 95%;
            }
        }

        .breadcrumbs {
            max-width : 80vw;
            min-width : 80vw;
        }
    }

    @media (max-width: 340px) {
        .training-information-container {
            padding : 0;
            display : flex;
            flex-direction : column;
            align-items : center;
            max-width : 96vw;
            min-width : 96vw;
            margin-top : 0;
            margin-bottom : 0;

            >div {
                min-width : 96%;
                max-width : 96%;
            }
        }

        .breadcrumbs {
            max-width : 96vw;
            min-width : 96vw;
        }
    }


`

export default IndividualTraining