import React from 'react';
import styled from 'styled-components';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const TrainingInfo = ({ training }) => {

    const rendering = () => {
        if(training) {
            return (
                <TrainingInfoSC>
                    <div className='info-column'>
                        <StarHalfIcon fontSize={"large"}/>
                        <p className='label'>Difficulty</p>
                        <p className='property-value'>{training.customProperties.difficulty.charAt(0).toUpperCase() + training.customProperties.difficulty.slice(1)}</p>
                    </div>
                    <div className='info-column'>
                        <LocationOnIcon fontSize={"large"}/>
                        <p className='label'>Location</p>
                        <div className='property-values-list'>
                            {training.customProperties.availableLocations.map((location) => {
                                return (
                                    <p className='property-value'>{location.place.charAt(0).toUpperCase() + location.place.slice(1)}</p>
                                )
                            })}
                        </div>
                    </div>
                    <div className='info-column'>
                        <CalendarMonthIcon fontSize={"large"}/>
                        <p className='label'>Duration</p>
                        <p className='property-value'>{`${training.numberOfDays} day${training.numberOfDays > 1 ? 's' : ''}`}</p>
                    </div>
                </TrainingInfoSC>
            );
        }

    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingInfoSC = styled.div`
    display : flex;
    justify-content : space-between;
    max-width : 100%;

    .info-column {
        padding : 20px;
        box-sizing : border-box;
        display : flex; 
        flex-direction : column;
        align-items : center;
        margin : 0 auto;
        border-right : 1px dotted lightgrey;
        max-width : 33.33%;

        &:last-child {
            border-right : none;
        }
        .label {
            font-size : 0.8rem;
            font-weight : bold;
        }
    }

    .property-value {
        font-size : 0.8rem;
        font-weight : 400;
        color : grey;
        text-align : center;
        padding : 0;
        margin : 5px;
    }
`

export default TrainingInfo