import React from 'react';
import styled from 'styled-components';
import { WorkshopsContext } from './Workshops';
import { MainContentContext } from '../mainContent/MainContent';

const Filtering = () => {

    const { filters, setFilters, trainings } = React.useContext(WorkshopsContext);

    const uniqueCategories = [...new Set(trainings.map(training => training.customProperties.category))];
    console.log('uniqueCategories', uniqueCategories);

    const rendering = () => {
        return (
            <FilteringSC>
                <p style={{textDecoration : filters.length === 3 && 'underline'}} onClick={() => setFilters(['qlik', 'powerbi', 'fabric'])}>All workshops</p>

                {uniqueCategories.includes('powerbi') && <p style={{textDecoration : filters === 'powerbi' && 'underline'}} onClick={() => setFilters('powerbi')}>PowerBI</p>}
                {uniqueCategories.includes('qlik') && <p style={{textDecoration : filters === 'qlik' && 'underline'}} onClick={() => setFilters('qlik')}>Qlik</p>}
                {uniqueCategories.includes('fabric') && <p style={{textDecoration : filters === 'fabric' && 'underline'}} onClick={() => setFilters('fabric')}>Fabric</p>}
            </FilteringSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FilteringSC = styled.div`
    display : flex; 
    font-weight : bold;
    font-size : 1.2em;
    gap : 20px;
    margin-bottom : 30px;

    &:hover {
        cursor : pointer; 
    }

    @media (max-width: 480px) {
        max-width : 98vw;
        margin : 40px auto;
        gap : 10px;
        justify-content : space-between;

        &>p {
            font-size : 0.95rem;

        }
    }

`

export default Filtering