import React from 'react';
import styled from 'styled-components';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReferenceTile from './ReferenceTile';
import { referencesArray } from './referencesArray';

const ReferencesCarousel = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

    const rendering = () => {
        return (
            <ReferencesCarouselSC>
                <h1>What they say about us:</h1>
                <Carousel 
                    responsive={responsive}
                    arrows={false}    
                >
                    {referencesArray.map((reference) => {
                        return (
                            <ReferenceTile reference={reference} />
                        )
                    })}
                </Carousel>
            </ReferencesCarouselSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ReferencesCarouselSC = styled.div`
    max-width : 1200px;
    margin : 0 auto;
    margin-top : 50px;

    h1 {
        text-align : center;
        font-size : 3rem;
        margin-bottom : 50px;
        color : #a4964e;
    }

    @media (max-width: 480px) {
        /* Styles for mobile phones */
        h1 {
            font-size : 2rem;
            max-width : 300px;
            margin-top : 20px;
            margin :0 auto;
            margin-bottom : 20px;
        }
    }
`

export default ReferencesCarousel